// TableForm.js
// noinspection JSCheckFunctionSignatures,JSValidateTypes

import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import './App.css'; // Link to our dark theme

function TableForm({ tableName, isTableNameValid }) {
    const defaultRow = {
        localizedName: '',
        name: 'c_',
        dataType: '',
        relatedEntity: '',
        relatedColumn: '',
        columnType: 'Custom',
        isForeignKey: false,
        maxLength: '',
        userReadOnly: false,
        required: false,
        requireExplicitAuthentication: false,
        unique: false,
        availableForList: false,
        renderWithTotals: false,
        booleanType: '',
        decimalScale: '',
        roleIds: '',
        selected: false
    };

    const [rows, setRows] = useState([defaultRow]);

    // Handle changes to decimalScale with validation
    const handleDecimalScaleChange = (index, value) => {
        let intValue = parseInt(value, 10);

        // Prevent invalid inputs and apply limits based on the dataType
        if (rows[index].dataType === 'currency') {
            if (intValue < 2) intValue = 2;
            if (intValue > 7) intValue = 7;
        } else if (rows[index].dataType === 'float') {
            if (intValue < 0) intValue = 0;
            if (intValue > 7) intValue = 7;
        }

        const updatedRows = [...rows];
        updatedRows[index].decimalScale = intValue;
        setRows(updatedRows);
    };

    const handleLocalizedNameChange = (index, e) => {
        const updatedRows = [...rows];
        updatedRows[index].localizedName = e.target.value;
        updatedRows[index].name = `c_${e.target.value.replace(/[^a-zA-Z]/g, '').toLowerCase()}`;
        setRows(updatedRows);
    };

    const handleNameChange = (index, e) => {
        const updatedRows = [...rows];
        let value = e.target.value;
        if (!value.startsWith('c_')) {
            value = `c_${value.replace(/[^a-z]/g, '')}`;
        } else {
            value = `c_${value.substring(2).replace(/[^a-z]/g, '')}`;
        }
        updatedRows[index].name = value;
        setRows(updatedRows);
    };

    const handleRelatedEntityChange = (index, e) => {
        const updatedRows = [...rows];
        const value = e.target.value.replace(/[^a-zA-Z]/g, '');
        updatedRows[index].relatedEntity = value.charAt(0).toUpperCase() + value.slice(1);
        setRows(updatedRows);
    };

    const handleMaxLengthChange = (index, value) => {
        let intValue = parseInt(value, 10);

        if (intValue < 1) {
            intValue = 1; // Automatically set to minimum value 1
        } else if (intValue > 256) {
            intValue = 256; // Automatically set to maximum value 256
        }

        const updatedRows = [...rows];
        updatedRows[index].maxLength = intValue; // Update the row with the corrected value
        setRows(updatedRows);
    };

    // Function to handle changes to roleIds with validation
    const handleRoleIdsChange = (index, e) => {
        const value = e.target.value;
        // Only allow numbers, commas, and spaces (if desired)
        const isValid = /^[0-9;]*$/.test(value);

        if (isValid) {
            const updatedRows = [...rows];
            updatedRows[index].roleIds = value;
            setRows(updatedRows);
        }
    };

    const checkForDuplicateNames = () => {
        const nameCount = {};
        rows.forEach(row => {
            if (nameCount[row.name]) {
                nameCount[row.name]++;
            } else {
                nameCount[row.name] = 1;
            }
        });
        return rows.map(row => nameCount[row.name] > 1);
    };

    const checkForMissingBooleanType = () => {
        return rows.map(row => row.dataType === 'bool' && !row.booleanType);
    };

    const checkForMissingFKFields = () => {
        return rows.map(row => row.dataType === 'fk' && (!row.relatedEntity || !row.relatedColumn));
    };

    const duplicateWarnings = checkForDuplicateNames();
    const booleanTypeWarnings = checkForMissingBooleanType();
    const fkWarnings = checkForMissingFKFields();

    const addNewRow = () => {
        setRows([...rows, { ...defaultRow }]);
    };

    const copySelectedRows = () => {
        const selectedRows = rows.filter(row => row.selected);
        const copiedRows = selectedRows.map(row => ({ ...row, selected: false }));

        // Reset selection of all rows (including newly copied rows)
        setRows([...rows, ...copiedRows].map(row => ({ ...row, selected: false })));
    };

    const deleteSelectedRows = () => {
        const updatedRows = rows.filter(row => !row.selected);

        // Reset selection of remaining rows
        setRows(updatedRows.map(row => ({ ...row, selected: false })));
    };

    const updateRow = (index, field, value) => {
        const updatedRows = [...rows];
        updatedRows[index][field] = value;

        if (field === 'dataType') {
            // Handle reset for decimalScale when switching away from 'float' or 'currency'
            if (updatedRows[index].dataType !== 'float' && updatedRows[index].dataType !== 'currency') {
                updatedRows[index].decimalScale = ''; // Clear decimalScale
            } else if ((updatedRows[index].dataType === 'float' || updatedRows[index].dataType === 'currency') && updatedRows[index].decimalScale === '') {
                // Set default decimalScale to 2 for float or currency dataTypes
                updatedRows[index].decimalScale = 2;
            }

            // Handle reset for booleanType when switching away from 'bool'
            if (updatedRows[index].dataType !== 'bool') {
                updatedRows[index].booleanType = ''; // Clear booleanType
            }

            // Handle reset for maxLength when switching away from 'string'
            if (updatedRows[index].dataType !== 'string') {
                updatedRows[index].maxLength = ''; // Clear maxLength
            } else if (updatedRows[index].dataType === 'string' && updatedRows[index].maxLength === '') {
                // Set default maxLength to 50 for string dataType
                updatedRows[index].maxLength = 50;
            }

            // Handle reset for relatedEntity and relatedColumn when switching away from 'fk'
            if (updatedRows[index].dataType !== 'fk') {
                updatedRows[index].relatedEntity = ''; // Clear relatedEntity
                updatedRows[index].relatedColumn = ''; // Clear relatedColumn
            }

            // Handle reset for unique when switching away from 'email' or 'string'
            if (!['email', 'string'].includes(updatedRows[index].dataType)) {
                updatedRows[index].unique = false; // Reset unique
            }

            // Handle default check for renderWithTotals for 'float', 'int', or 'currency'
            updatedRows[index].renderWithTotals = ['float', 'int', 'currency'].includes(updatedRows[index].dataType);

            // If dataType is 'fk', set isForeignKey to true, otherwise reset it
            updatedRows[index].isForeignKey = value === 'fk';
        }

        setRows(updatedRows);
    };


    const toggleRowSelection = (index) => {
        const updatedRows = [...rows];
        updatedRows[index].selected = !updatedRows[index].selected;
        setRows(updatedRows);
    };

    const getExportData = () => {
        return rows.map(({ selected, ...rowData }) => rowData);
    };

    return (
        <div className="App">
            {/* Dynamically display the table name here */}
            <h2>Custom Data Dictionary for {tableName}</h2>
            <table>
                <thead>
                <tr>
                    <th>Select</th>
                    <th>Label</th>  {/*localizedName*/}
                    <th>Column</th>  {/*name*/}
                    <th>Data Type</th>  {/*dataType*/}
                    <th>Related Entity</th>  {/*relatedEntity*/}
                    <th>Related Column</th>  {/*relatedColumn*/}
                    <th>Column Type</th>  {/*columnType*/}
                    <th>Is Foreign Key</th>  {/*isForeignKey*/}
                    <th>Max Length</th>  {/*maxLength*/}
                    <th>Read Only</th>  {/*userReadOnly*/}
                    <th>Required</th>  {/*required*/}
                    <th>E-Sig Required</th>  {/*requireExplicitAuthentication*/}
                    <th>Unique</th>  {/*unique*/}
                    <th>In List View</th>  {/*availableForList*/}
                    <th>Render Totals</th>  {/*renderWithTotals*/}
                    <th>Boolean Type</th>  {/*booleanType*/}
                    <th>Decimals</th>  {/*decimalScale*/}
                    <th>Role Ids</th>  {/*roleIds*/}
                </tr>
                </thead>
                <tbody>
                {rows.map((row, index) => (
                    <React.Fragment key={index}>
                        <tr>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={row.selected}
                                    onChange={() => toggleRowSelection(index)}
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={row.localizedName}
                                    onChange={(e) => handleLocalizedNameChange(index, e)}
                                    disabled={!isTableNameValid}
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={row.name}
                                    onChange={(e) => handleNameChange(index, e)}
                                    disabled={!isTableNameValid}
                                />
                            </td>
                            <td>
                                <select
                                    value={row.dataType}
                                    onChange={(e) => updateRow(index, 'dataType', e.target.value)}
                                    disabled={!isTableNameValid}
                                >
                                    <option value="">Select dataType</option>
                                    <option value="bool">bool</option>
                                    <option value="date">date</option>
                                    <option value="datetime">datetime</option>
                                    <option value="email">email</option>
                                    <option value="fk">fk</option>
                                    <option value="float">float</option>
                                    <option value="int">int</option>
                                    <option value="currency">currency</option>
                                    <option value="document">document</option>
                                    <option value="image">image</option>
                                    <option value="media">media</option>
                                    <option value="html">html</option>
                                    <option value="hyperlink">hyperlink</option>
                                    <option value="text">text</option>
                                    <option value="string">string</option>
                                </select>
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={row.relatedEntity}
                                    onChange={(e) => handleRelatedEntityChange(index, e)}
                                    disabled={row.dataType !== 'fk'}
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={row.relatedColumn}
                                    onChange={(e) => updateRow(index, 'relatedColumn', e.target.value)}
                                    disabled={row.dataType !== 'fk'}
                                />
                            </td>
                            <td>
                                <input type="text" value={row.columnType} disabled/>
                            </td>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={row.isForeignKey}
                                    disabled
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    value={row.maxLength}
                                    onChange={(e) => handleMaxLengthChange(index, e.target.value)}
                                    disabled={row.dataType !== 'string'}
                                    min={1}
                                    max={256}
                                    placeholder="Enter a value from 1-256"
                                />
                            </td>

                            <td>
                                <input
                                    type="checkbox"
                                    checked={row.userReadOnly}
                                    onChange={(e) => updateRow(index, 'userReadOnly', e.target.checked)}
                                />
                            </td>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={row.required}
                                    onChange={(e) => updateRow(index, 'required', e.target.checked)}
                                />
                            </td>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={row.requireExplicitAuthentication}
                                    onChange={(e) => updateRow(index, 'requireExplicitAuthentication', e.target.checked)}
                                />
                            </td>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={row.unique}
                                    onChange={(e) => updateRow(index, 'unique', e.target.checked)}
                                    disabled={!(["email", "string"].includes(row.dataType))}
                                />
                            </td>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={row.availableForList}
                                    onChange={(e) => updateRow(index, 'availableForList', e.target.checked)}
                                />
                            </td>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={row.renderWithTotals}
                                    onChange={(e) => updateRow(index, 'renderWithTotals', e.target.checked)}
                                    disabled={!(["float", "int", "currency"].includes(row.dataType))}
                                />
                            </td>
                            <td>
                                <select
                                    value={row.booleanType}
                                    onChange={(e) => updateRow(index, 'booleanType', e.target.value)}
                                    disabled={row.dataType !== 'bool'}
                                    className={row.dataType !== 'bool' ? 'disabled' : ''}
                                >
                                    <option value="">Select Boolean Type</option>
                                    <option value="True/False">True/False</option>
                                    <option value="Yes/No">Yes/No</option>
                                </select>
                            </td>
                            <td>
                                <input
                                    type="number"
                                    value={row.decimalScale}
                                    onChange={(e) => handleDecimalScaleChange(index, e.target.value)}
                                    disabled={!(["float", "currency"].includes(row.dataType))}
                                    min={row.dataType === 'currency' ? 2 : 0}
                                    max={7}
                                    placeholder="Decimal precision"
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={row.roleIds}
                                    onChange={(e) => handleRoleIdsChange(index, e)}
                                    placeholder="Enter Role IDs (e.g., 1;100;105)"
                                    onBlur={() => {
                                        // Remove any trailing commas when the input loses focus
                                        const updatedRoleIds = row.roleIds.replace(/;+$/, ''); // Regex to remove trailing commas
                                        updateRow(index, 'roleIds', updatedRoleIds);
                                    }}
                                />
                            </td>
                        </tr>

                        {/* Warning for duplicate names */}
                        {duplicateWarnings[index] && (
                            <tr>
                                <td colSpan="18" className="warning-text">
                                    Warning: Duplicate 'name' value detected!
                                </td>
                            </tr>
                        )}

                        {/* Warning for missing booleanType when dataType is 'bool' */}
                        {booleanTypeWarnings[index] && (
                            <tr>
                                <td colSpan="18" className="warning-text">
                                    Warning: 'booleanType' must be selected when 'dataType' is 'bool'.
                                </td>
                            </tr>
                        )}

                        {/* Warning for missing relatedEntity or relatedColumn when dataType is 'fk' */}
                        {fkWarnings[index] && (
                            <tr>
                                <td colSpan="18" className="warning-text">
                                    Warning: Both 'relatedEntity' and 'relatedColumn' must be filled when 'dataType' is 'fk'.
                                </td>
                            </tr>
                        )}
                    </React.Fragment>
                ))}
                </tbody>
            </table>

            <div className="button-group">
                <button onClick={addNewRow}>Add New Row</button>
                <button onClick={deleteSelectedRows}>Delete Selected Rows</button>
                <button onClick={copySelectedRows}>Copy Selected Rows</button>
                <CSVLink data={getExportData()} filename={`${tableName}-data.csv`}>
                    <button disabled={duplicateWarnings.some(Boolean)}>Export to CSV</button>
                </CSVLink>
            </div>
        </div>
    );
}

export default TableForm;
